import React  from 'react';
import Fade from 'react-reveal/Fade';


import './resume.css';


const Resume = () => (
    <div className="resume">
        <div className="resume-container">
            <h1 id="main">Andres Rangel</h1>
            <h2>Chicago, IL</h2>
            <h2><a href="mailto:ar@andresrangel.com?Subject=Hello!" target="_top">ar@andresrangel.com</a></h2>
            <div className="summary">
                <a href="https://drive.google.com/file/d/1tFhpAQQm1S_E9MsDTMbQw7LxJuPuFYr6/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <div className="resume-download">
                    <h4><u>Download Resumé</u></h4>
                    </div>
                </a>
            </div>
            <div className="experience">
                <h1 id="submain">/Experience</h1>
                <div className="seperator"></div>
                <div className="rcontent">
                    <h2 id="c-name">YMCA of Metro Chicago</h2>
                    <p><a href="https://www.ymcachicago.org/" target="_blank" rel="noopener noreferrer">ymcachicago.org</a></p>
                    <br></br>
    
                    <h2 id="c-name">Digital Experience Manager</h2>
                    
                    <h3>July 2021 - <i>present</i></h3>


                    <p>» Co-lead six-figure website redesign through RFP process, vendor selection, development, content strategy, and deployment </p>
                    <p>» Sole owner of the web experience, focusing on accuracy, speed, SEO, automation, design, content, and governance</p>
                    <p>» Integrate local SEO practices, improving organic traffic</p>
                    <p>» Streamline email marketing by establishing automations, design,and data integrity; increased open rate</p>
                    <p>» Manage vendor relationships and onboarding for creative agency, web vendor, and SEO consulting service </p>
                    <p>» Serve on the Policy Committee for the Y’s Equity, Diversity, and Inclusion Team</p>
                    <p>» Rehaul app experience by integrating parent company’s app</p>
                    
                    <br></br>

                    <h2 id="c-name">Website Coordinator</h2>
                    <h3><u></u>February 2020 – June 2020 (PT), June 2020 - July 2021 (FT)</h3>


                    <p>» Remodeled the summer camp hurdle by redesigning and automating postings through the CCC API, saving 80% of the listing process.</p>
                    <p>» Handled 18 locations’ online presence through social media, website, email, and mobile app</p>
                    <p>» Developed web pages optimizing SEO, mobile responsiveness, WCAG, and load time</p>
                    <p>» Redesigned 3rd party mobile app with design team, increasing online registrations and location visits</p>
                    <p>» Designed job-aides, flyers, landing pages, and graphics</p>
                </div>

                <div className="rcontent">
                    <h2 id="c-name">Chew On This </h2>
                    <p><a href="https://www.chewonthismealprep.com/" target="_blank" rel="noopener noreferrer">chewonthismealprep.com</a></p>
                    <h3><u></u>July 2019 - March 2021</h3>
                    
                    <p>» Designed and developed ecommerce store</p>
                    <p>» Established digital presence through social media, website, and online listings management</p>
                    <p>» Coordinated digital assets with designer</p>
                </div>

                <div className="rcontent">
                    <h2 id="c-name">The Whole Food Nut</h2>
                    <p><a href="https://thewholefoodnut.com/" target="_blank" rel="noopener noreferrer">thewholefoodnut.com</a></p>
                    <h3><u></u>March 2019 - February 2021</h3>

                    <p>» Assist in content deployment</p>
                    <p>» Front-end and back-end maintenance with WordPress</p>
                    <p>» Developed and designed webpages using UI/UX methodologies</p>
                    <p>» Designed animated GIFs, wireframes, and concepts</p>
                </div>

                {/* <div className="rcontent">
                    <h2 id="c-name">Imarie Lopez Productions</h2>
                    <p><a href="https://imarielopez.com/" target="_blank" rel="noopener noreferrer">imarielopez.com</a></p>
                    <h3><u></u>March 2019 - May 2019</h3>

                    <p>» Designed and developed the website</p>
                    <p>» Developed using ReactJS and WordPress </p>
                    <p>» SSL, web traffic, maintenance, hosting, etc.</p>
                </div>

                <div className="rcontent">
                    <h2 id="c-name">shift.</h2>
                    <p><a href="https://www.shiftyourmindclothing.com/" target="_blank" rel="noopener noreferrer">shiftyourmindclothing.com</a></p>
                    <h3><u></u>Jan 2019 -  March 2019</h3>

                    <p>» Redesigned Shopify store to fit brand using .liquid syntax.</p>
                    <p>» Created landing page, CSS logo animation, and styles</p>
                    <p>» Standalone webstore in development.</p>
                </div> */}

            </div>

            <div className="experience">
            <h1 id="submain">Education</h1>
                <div className="seperator"></div>
                <h2><a href="https://now.uiowa.edu/sites/now.uiowa.edu/files/spring-18-graduation-iowa-now.pdf"><strong>University of Iowa //  Informatics B.A.</strong></a></h2>
                <h3>Aug 2014 - May 2018</h3>


            </div>

            <div className="skills">
                <h1 id="submain">Skills</h1>
                 <div className="skill-container">

                    <Fade effect="fadeInUp"><div className="skillA"><h4>HTML</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>CSS</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>JS</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>SQL</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>PHP</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>ReactJS</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Python</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>SEO</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>CRO</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>A/B Testing</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>SEMrush</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Hotjar</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Google Analytics</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Google Tag Manager</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Google Ads</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Google My Business</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Google Search Console</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Adobe Photoshop</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Adobe Illustrator</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Adobe InDesign</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Adobe After Effects</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>UI/UX</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>InVision</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Graphic Design</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Prototyping</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Wireframing</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Facebook Ads</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Facebook Business Manager</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Social Media</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Salesforce</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>ActiveCampaign</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>WordPress</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Kentico</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>ExpressionEngine</h4></div></Fade>
                    <Fade effect="fadeInUp"><div className="skillA"><h4>Drupal</h4></div></Fade>
                </div>   
            </div>
        </div>
    </div>
);

export default Resume;